import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, FormControl, FormLabel, Input, Button, Select} from '@chakra-ui/react'
import {useState} from 'react';


const NewUserModal = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    let [username, setUsername] = useState("user");
    let [idstation, setIdstation] = useState("fr_1_user");
    let [role, setRole] = useState("user");
    
    const resetDefaultValues = () => {
        setUsername("user");
        setIdstation("fr_1_user");
        setPassword(generateRandomPassword());
    }

    const generateRandomPassword = () => {
        let tab = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        let size = 10;
        let pwd = "";
        
        for (let i = 0; i < size; i++)
        {
            pwd += tab[Math.floor(Math.random() * ((tab.length - 1)))];
        }
        
        return pwd;
    }
    let [password, setPassword] = useState(generateRandomPassword());
    
    const onCancel = () => {
        props.cbCancel();
    }

    const onSubmit = () => {
        props.cbValidate(username, idstation, password, role);
        resetDefaultValues();
    }

    return (
        <Modal isOpen={props.open} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create your account</ModalHeader>
                <ModalCloseButton onClick={onCancel} />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Username</FormLabel>
                        <Input
                            value={username} 
                            onChange={(event) => {setUsername(event.target.value)}}
                        />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Idstation</FormLabel>
                        <Input
                            value={idstation}
                            onChange={(event) => {setIdstation(event.target.value)}}
                        />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>password</FormLabel>
                        <Input 
                            value={password}
                            onChange={(event) => {setPassword(event.target.value)}}
                        />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Role</FormLabel>
                        <Select value={role} onChange={(event) => {setRole(event.target.value)}}>
                            <option value='user'>User</option>
                            <option value='technician'>Technician</option>
                        </Select>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onSubmit}>Create</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default NewUserModal;