import {Box, VStack, Flex, Text, IconButton, StackDivider, CircularProgress, useToast, HStack} from '@chakra-ui/react';
import {FcPlus} from 'react-icons/fc'
import UserInfos from '@Components/UserInfos';
import {getCookie} from 'react-use-cookie';
import { useState, useEffect } from 'react';

import './styles/Users.css'
import NewUserModal from '@Components/NewUserModal';
import BodyReader from '@Components/BodyReader';
import NavBar from '@Components/NavBar'

const base64 = require('base-64');

const Users = (props) => {
    const [loading, setLoading] = useState(true);
    const [userToMod, setUserToMod] = useState(-1);
    const [usersList, setUsersList] = useState([]);
    const [newUserModal, setNewUserModal] = useState(false);
    const toast = useToast();

    const getUsers = () => {
        let authorization = "Bearer " + getCookie("token");
        fetch("https://yj7uvp6vc5.execute-api.eu-west-3.amazonaws.com/prod/user", {
            method: 'GET',
            mode: 'cors',
            Accept: "*/*",
            headers: {
                "x-api-key": "7aEfdjIqxw5LICUA2Yb694sq1RrW7IS67q8KekK5",
                "Authorization": authorization,
                'Content-Type': 'application/json'
            },
      }).then(async (data) => {
            let body = await BodyReader(data.body);
            body = JSON.parse(body);
            if (data.status === 200) {
                setUsersList(body.Items);
                setLoading(false);
            } else {
                console.log(body);
                openToast("Unexpected error.", "error");
            }
      });
    }

    useEffect(() => {
        getUsers();
    }, []);

    const onButtonNewUser = () => {
        setNewUserModal(true);
    }

    const openToast = (message, status) => {
        toast({
            title: message,
            status: status,
            duration: 5000,
            isClosable: true,
        });
    } 

    const onNewUserModalValidate = (username, idstation, password, role) => {
        setNewUserModal(false);
        let encodedpwd = base64.encode(password);
        let data =  {
            "data": {
                "username": username,
                "idstation": idstation,
                "password": encodedpwd,
                "role": role
            }
        };
        data = JSON.stringify(data);

        setLoading(true);
        let authorization = "Bearer " + getCookie("token");
        // New user request.
        fetch("https://yj7uvp6vc5.execute-api.eu-west-3.amazonaws.com/prod/user", {
            method: 'POST',
            mode: 'cors',
            Accept: "*/*",
            headers: {
                "x-api-key": "7aEfdjIqxw5LICUA2Yb694sq1RrW7IS67q8KekK5",
                "Authorization": authorization,
                'Content-Type': 'application/json'
            },
            body: data
        }).then(async (data) => {
            if (data.status === 200)
            {
                const blob = new Blob(["password: " + password], {type: 'text/plain'});
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, username + ".txt");
                } else {
                    const elem = window.document.createElement('a');
                    elem.href = window.URL.createObjectURL(blob);
                    elem.download = username + ".txt";
                    document.body.appendChild(elem);
                    elem.click();
                    document.body.removeChild(elem);
                }
                openToast("User successfuly created.", "success");
                getUsers();
                return;
            } else if (data.status === 400) {
                openToast("Invalid username.", "error");
            } else {
                openToast("Unexpected error. Please retry in a few moment.", "error");
            }
            
            setLoading(false);

            let result = await BodyReader(data.body);
            console.log("body: " + result);
        }).catch((err) => {
            console.log(err);
        });
    };

    const onNewUserModalCancel = () => {
        setNewUserModal(false);
    }

    const onModifyUser = (key) => {
        setUserToMod(key);
    };

    const onDeleteUser = (key) => {
        console.log("delete user " + usersList[key].username);
        let data =  {
            "data": {
                "username": usersList[key].username,
            }
        };
        data = JSON.stringify(data);

        let authorization = "Bearer " + getCookie("token");
        fetch("https://yj7uvp6vc5.execute-api.eu-west-3.amazonaws.com/prod/user", {
            method: 'DELETE',
            mode: 'cors',
            Accept: "*/*",
            headers: {
                "x-api-key": "7aEfdjIqxw5LICUA2Yb694sq1RrW7IS67q8KekK5",
                "Authorization": authorization,
                'Content-Type': 'application/json'
            },
            body: data
        }).then(async (data) => {
            if (data.status === 200) {
                openToast("User succefully delete", "success");
            } else {
                openToast("Unexpected error.", "error");
            }
            let result = await BodyReader(data.body);
            console.log("body: " + result);
            setLoading(true);
            getUsers();
        });
    }

    const onCancelMod = () => {
        setUserToMod(-1);
    };

    const onValidateMod = async (defaultUsername, username, idstation, role) => {
        let data =  {
            "data": {
                "username": defaultUsername,
                "newusername": username,
                "idstation": idstation,
                "role": role
            }
        };
        data = JSON.stringify(data);
        console.log("data " + data);
        let authorization = "Bearer " + getCookie("token");
        fetch("https://yj7uvp6vc5.execute-api.eu-west-3.amazonaws.com/prod/user", {
            method: 'PUT',
            mode: 'cors',
            Accept: "*/*",
            headers: {
                "x-api-key": "7aEfdjIqxw5LICUA2Yb694sq1RrW7IS67q8KekK5",
                "Authorization": authorization,
                'Content-Type': 'application/json'
            },
            body: data
        }).then(async (data) => {
            if (data.status === 200) {
                usersList[userToMod].username = username;
                usersList[userToMod].idstation = idstation;
                usersList[userToMod].role = role;
                setUserToMod(-1);
                openToast("User successfuly modified", "success");
                return;
            }
            openToast("Unexpected error", "error");
            let result = await BodyReader(data.body);
            console.log("body: " + result);
            setLoading(true);
            getUsers();
        }).catch((err) => {
            console.log(err);
        });
    }

    const renderUsers = () => {
        if (loading)
            return <CircularProgress isIndeterminate color='green.300' size={200} />;
        return usersList.map((element, i) => {
                return (
                    <UserInfos
                        element={element}
                        key={i}
                        index={i}
                        cbModify={onModifyUser}
                        cbDelete={onDeleteUser}
                        cbCancelMod={onCancelMod}
                        cbValidateMod={onValidateMod}
                        toMod={i === userToMod}
                    />);
        })
    }

    return (
        <>
        <NavBar home={true} logout={true}/>
        <Box className={'window'}>
            {<NewUserModal open={newUserModal} cbCancel={onNewUserModalCancel} cbValidate={onNewUserModalValidate}/>}
            <Box className={'containerInputLogin'}>
              <h1 className={'title'}>Users</h1>
            <VStack divider={<StackDivider borderColor='grey'/>}>
                    <Flex bg={'white'}>
                        <Box w='400px' marginRight={2}>
                            <Text fontSize='24' align='left'>Username</Text>
                        </Box>
                        <Box w='300px' marginRight={2}>
                            <Text fontSize='24' align='left'>ID_station</Text>
                        </Box>
                        <Box w='200px' marginRight={2}>
                            <Text fontSize='24' align='left'>Role</Text>
                        </Box>
                        <HStack>
                            <IconButton></IconButton>
                            <IconButton
                                colorScheme='blue'
                                aria-label='Search database'
                                icon={<FcPlus/>}
                                onClick={onButtonNewUser}
                            />
                        </HStack>
                    </Flex>
                    {renderUsers()}
            </VStack>
            </Box>
        </Box>
        </>
    )
}

export default Users;