import React, { useState, useCallback } from 'react';
import { setCookie } from 'react-use-cookie';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import NavBar from '@Components/NavBar';
import './styles/Login.css';

var base64 = require('base-64');

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [pass, setPassword] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

    const ActionLogin = useCallback(() => {
      let authorization = "Basic " + base64.encode(username + ":" + pass);
      console.log("Authorization " + authorization);
      fetch("https://yj7uvp6vc5.execute-api.eu-west-3.amazonaws.com/prod/login", {
        method: 'POST',
        mode: 'cors',
        Accept: "*/*",
        headers: {
          "x-api-key": "7aEfdjIqxw5LICUA2Yb694sq1RrW7IS67q8KekK5",
          "Authorization": authorization,
          'Content-Type': 'application/json'
        },
        body: ""
      })
      .then(async (data) => {
        if (data.status === 200)
        {
          toast({
            title: "Successfuly logged in.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          const utf8Decoder = new TextDecoder("utf-8");
          const reader = data.body.getReader();
          let body = "";

          await reader.read().then(function processText({ done, value }) {
            if (done) {
              console.log("Stream complete");
              return;
            }
            body += utf8Decoder.decode(value, {stream: true});
            return reader.read().then(processText);
          });
          console.log("body: " + body);
          body = JSON.parse(body);
          if (body.token) {
            console.log(body.token);
              setCookie("token", body.token, {hours:2, SameSite: 'Lax',});
          }
          navigate("/home");
        } else if (data.status === 401) {
          toast({
            title: "Invalid Username/Password",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error, please retry in a few moment.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        console.log(data);
      });
    }, [toast, username, pass, navigate]);

    return (
      <>
      <NavBar home={false} logout={false}/>
        <div>
            <div className={'containerInputLogin'}>
              <h1 className={'title'}>Hi! Please sign in.</h1>
              <form onSubmit={(e) => {e.preventDefault();}} className={`inputContainer`}>
                <input className={'InputLogin'} type="username" placeholder="Username" autoFocus value={username} onChange={e => setUsername(e.target.value)} />
                <input className={'InputLogin'} type="password" placeholder="Password" value={pass} onChange={e => setPassword(e.target.value)}/>
                <button type="submit" className={'ButtonLogin'} onClick={ActionLogin}>Sign in</button>
              </form>
            </div>
        </div>
      </>
    );
}

export default Login