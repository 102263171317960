import Login from '@Components/Pages/Login';
import Home from '@Components/Pages/Home';
import Users from '@Components/Pages/Users';
import CheckAuth from '@Components/CheckAuth'
import CheckAdminAuth from '@Components/CheckAdminAuth';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import { BrowserRouter as Router, Routes, Route} from 'react-router-dom' 

import './App.css';

const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: ""
      },
    }),
  }
});


function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path='/' exact element={<CheckAuth><Home/></CheckAuth>}/>
          <Route path='/login' element={<Login />}/>
          <Route path='/home' element={ <CheckAuth><Home/></CheckAuth>}/>
          <Route path='/users' element={ <CheckAdminAuth><Users/></CheckAdminAuth>}/>
          <Route path='*' element={<h1>ERROR 404 - Not found</h1>}/>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
