import {Box, Flex, Text, IconButton, Input, HStack} from '@chakra-ui/react'
import {BsFillPencilFill} from 'react-icons/bs'
import {RiDeleteBin2Line} from 'react-icons/ri'
import { FcApproval } from 'react-icons/fc';
import {CgCloseR} from 'react-icons/cg';
import { useState } from 'react';
import { Select } from '@chakra-ui/react';

const UserInfos = (props) => {
    let defaultUsername = props.element.username;
    let [username, setUsername] = useState(props.element.username);
    let [idstation, setIdstation] = useState(props.element.idstation);
    let [role, setRole] = useState(props.element.role);

    const onClickMod = () => {
        props.cbModify(props.index);
    };

    const onClickDelete = () => {
        props.cbDelete(props.index);
    };

    const onClickCancel = () => {
        props.cbCancelMod();
    }

    const onClickValidate = () => {
        props.cbValidateMod(defaultUsername, username, idstation, role);
    }

    const onUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const onIdstationChange = (event) => {
        setIdstation(event.target.value);
    }

    if (props.toMod)
    {
        return (
            <Box>
                <Flex>
                    <Box w='400px' marginRight={2}>
                        <Input value={username} onChange={onUsernameChange} size='xs' h='100%'/>
                    </Box>
                    <Box w='300px' marginRight={2}>
                        <Input value={idstation} onChange={onIdstationChange} size='xs' h='100%'/>
                    </Box>
                    <Box w='200px' marginRight={2}>
                        <Select value={role} onChange={(event) => {setRole(event.target.value)}}>
                            <option value='user'>User</option>
                            <option value='technician'>Technician</option>
                        </Select>
                    </Box>
                    <HStack>
                    <IconButton
                        colorScheme='blue'
                        aria-label='Search database'
                        icon={<FcApproval/>}
                        onClick={onClickValidate}
                    />
                    <IconButton
                        colorScheme='blue'
                        aria-label='Search database'
                        icon={<CgCloseR />}
                        onClick={() => onClickCancel()}
                    />
                    </HStack>
                </Flex>
            </Box>
        );
    }
    return (
        <Box>
            <Flex>
                <Box w='400px' marginRight={2} margin='auto'>
                    <Text h='100%'>{props.element.username}</Text>
                </Box>
                <Box w='300px' marginRight={2} margin='auto'>
                    <Text h='100%'>{props.element.idstation}</Text>
                </Box>
                <Box w='200px'  marginRight={2} margin='auto'>
                    <Text h='100%'>{props.element.role}</Text>
                </Box>
                <HStack>
                <IconButton
                    colorScheme='blue'
                    aria-label='Search database'
                    icon={<BsFillPencilFill />}
                    onClick={onClickMod}
                />
                <IconButton
                    colorScheme='blue'
                    aria-label='Search database'
                    icon={<RiDeleteBin2Line/>}
                    onClick={onClickDelete}
                />
                </HStack>
            </Flex>
        </Box>
    );
}

export default UserInfos;