import React, { useEffect, useState } from 'react';
import { getCookie } from 'react-use-cookie';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Box, Center } from '@chakra-ui/react'

const CheckAuth = ({children}) => {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!getCookie("token")) {
            console.log("CheckAuth: No token -> navigate to login");
            navigate("/login");
            return;
        }
        let authorization = "Bearer " + getCookie("token");
        
        fetch("https://yj7uvp6vc5.execute-api.eu-west-3.amazonaws.com/prod/token", {
            method: 'GET',
            mode: 'cors',
            Accept: "*/*",
            headers: {
            "x-api-key": "7aEfdjIqxw5LICUA2Yb694sq1RrW7IS67q8KekK5",
            "Authorization": authorization,
            'Content-Type': 'application/json'
        },})
        .then(async (data) => {
            console.log(data);
            if (data.status === 200) {
                console.log("Check auth OK");
                const utf8Decoder = new TextDecoder("utf-8");
                const reader = data.body.getReader();
                let body = "";
                
                await reader.read().then(function processText({ done, value }) {
                    if (done) {
                        console.log("Stream complete");
                        return;
                    }
                    body += utf8Decoder.decode(value, {stream: true});
                    return reader.read().then(processText);
                });
                console.log("body: " + body);
                body = JSON.parse(body);
                setData(body.data);
                setLoading(false);
            } else {
                console.log("CheckAuth: Invalid token -> navigate to login");
                navigate("/login");
            }
        })
    }, []);

    if (loading)
    {
        return (
            <Center>
                <Box>
                    <CircularProgress isIndeterminate color='green.300' size={200} />
                </Box>
            </Center>
        );
    } else {
        return <>{React.cloneElement(children, {data: data})}</>;
    }
}

export default CheckAuth;