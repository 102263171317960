import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Flex,
    HStack,
    IconButton,
    useBreakpointValue,
    useColorModeValue,
    Image
  } from '@chakra-ui/react'
import { FiMenu } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom';
import { setCookie } from 'react-use-cookie';

const NavBar = (props) => {
    const navigate = useNavigate();
    const onPm2sImageClick = () => {
      navigate("/users");
    };

    const onHomeBtn = () => {
      navigate("/home");
    }

    const onLogoutBtn = () => {
      setCookie("token", undefined);
      navigate("/login");
    }

    return (
        <Box
        as="section"
      >
        <Box as="nav" bg="bg-surface" boxShadow={useColorModeValue('sm', 'sm-dark')}>
            <HStack spacing="10" justify="space-between">
                <Flex marginLeft={25} justify="space-between" flex="1">
                  <ButtonGroup variant="solid" spacing="8">
                    { props.home ?
                    (['Home'].map((item) => (
                      <Button key={item} onClick={onHomeBtn}>{item}</Button> ))) : null }
                  { props.logout ? (<Button variant="ghost" onClick={onLogoutBtn}>Logout</Button>) : null }
                  </ButtonGroup>
                </Flex>
                <Flex justify="space-between" flex="1">
                <Image src="logo.png" width={345} height={150}/>
                </Flex>
                <Flex justify="space-between" marginRight={25}>
                    <Image src="logo_pm2s.png" width={300} height={100} onClick={onPm2sImageClick} />
                </Flex>
            </HStack>
        </Box>
      </Box>
  )
}

export default NavBar;