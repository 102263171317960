import {useEffect, useState} from 'react';
import {useToast, Button, Spinner} from '@chakra-ui/react';

import NavBar from '@Components/NavBar';
import IDStationModal from '@Components/IDStationModal';

import './styles/Home.css';


function Home(props) {
    const [loadingRessources, setLoadingRessources] = useState(true);
    const [loadingMsi, setLoadingMsi] = useState(true);
    const [zipFileDownloadLink, SetZipFileDownloadLink] = useState("");
    const [msiZipFileDownloadLink, SetMsiZipFileDownloadLink] = useState("");
    const [promptDialogIDStation, setPromptDialogIDStation] = useState(false);
    const [idstation, setIdstation] = useState(props.data.idstation);
    const toast = useToast();

    function onClick() {
        window.location.replace(zipFileDownloadLink);
        setTimeout(() => { 
            window.location.replace(msiZipFileDownloadLink);
         }, 1000);
    };

    useEffect(()=> {
        console.log("role " + props.data.role);
        console.log("idstation " + idstation);
        if ((props.data.role === "superuser" || props.data.role === "technician") && (idstation === "")) {
            console.log("CHOOSE IDSTATION");
            setPromptDialogIDStation(true);
            return;
        }
        // fetch("https://x7rx0sodlj.execute-api.eu-west-3.amazonaws.com/default/ressources_update?" + new URLSearchParams({
        //     body: JSON.stringify({
        //         data: {
        //             lastUpdateDate: "0000-00-00T00:00:00.000Z",
        //         },
        //         id_station: idstation,
        //         request: "ressources_update"
        //     })
        // }), {
        //     method: 'GET',
        //     mode: 'cors',
        //     Accept: "*/*",
        //     headers: {
        //     "x-api-key": "DkLuxTRX1u3j7zkDOBnAo1LoaOMqCzLo6c4XkxM5",
        //     'Content-Type': 'application/json'
        //     },
        // })
        fetch("https://i73e34eowtiknxzxol7darosge0vnwwf.lambda-url.eu-west-3.on.aws?" + new URLSearchParams({
            body: JSON.stringify({
                data: {
                    lastUpdateDate: "0000-00-00T00:00:00.000Z",
                },
                id_station: idstation,
                request: "ressources_update"
            })
        }), {
            method: 'GET',
            mode: 'cors',
            Accept: "*/*",
            headers: {
            'Content-Type': 'application/json'
            },
        })
        .then(async (data) => {
            console.log(data);
            if (data.status === 200) {
                console.log("Zip file generated successfuly");
                const utf8Decoder = new TextDecoder("utf-8");
                const reader = data.body.getReader();
                let body = "";
                
                await reader.read().then(function processText({ done, value }) {
                    if (done) {
                        console.log("Stream complete");
                        return;
                    }
                    body += utf8Decoder.decode(value, {stream: true});
                    return reader.read().then(processText);
                });
                console.log("body: " + body);
                body = JSON.parse(body);
                SetZipFileDownloadLink(body.data);
                setLoadingRessources(false);
            } else {
                toast({
                    title: "Error, please retry in a few moment.",
                    description: "If the error persist please contact the support.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        })

        fetch("https://x7rx0sodlj.execute-api.eu-west-3.amazonaws.com/default/software_update?" + new URLSearchParams({
            body: JSON.stringify(
                {
                    "id_station": idstation,
                    "request": "software_update",
                    "data": {
                        "version": "0.0.0.0",
                        "msi": true
                    }
                })
        }), {
            method: 'GET',
            mode: 'cors',
            Accept: "*/*",
            headers: {
            "x-api-key": "DkLuxTRX1u3j7zkDOBnAo1LoaOMqCzLo6c4XkxM5",
            'Content-Type': 'application/json'
            },
        })
        .then(async (data) => {
            console.log(data);
            if (data.status === 200) {
                console.log("Zip file generated successfuly");
                const utf8Decoder = new TextDecoder("utf-8");
                const reader = data.body.getReader();
                let body = "";
                
                await reader.read().then(function processText({ done, value }) {
                    if (done) {
                        console.log("Stream complete");
                        return;
                    }
                    body += utf8Decoder.decode(value, {stream: true});
                    return reader.read().then(processText);
                });
                console.log("body: " + body);
                body = JSON.parse(body);
                SetMsiZipFileDownloadLink(body.data);
                setLoadingMsi(false);
            } else {
                toast({
                    title: "Error, please retry in a few moment.",
                    description: "If the error persist please contact the support.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        })

    }, [setLoadingRessources, setLoadingMsi, toast, props, idstation]);

    const onSubmitIDStationModal = (newIdstation) => {
        console.log("onSubmitIDStationModal " + newIdstation);
        setIdstation(newIdstation);
        setPromptDialogIDStation(false);
    }

    return (
        <>
            <IDStationModal open={promptDialogIDStation} cbValidate={onSubmitIDStationModal}/>
            <NavBar home={true} logout={true}/>
            <div className={'window'}>
                <div className={'containerInputLogin'}>
                  <h1 className={'title'}>Welcome, {props.data.username} !</h1>
                    <Button
                        isLoading={loadingRessources || loadingMsi}
                        colorScheme='blue'
                        width={'40%'}
                        fontSize={24}
                        spinner={<Spinner size='md' color='white' />}
                        onClick={onClick}
                        marginTop='3%'
                        >
                        Download
                    </Button>
                </div>
            </div>
        </>
    );
}

export default Home;