import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, FormControl, FormLabel, Input, Button} from '@chakra-ui/react'
import {useState} from 'react';


const IDStationModal = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    let [idstation, setIdstation] = useState("");

    const onSubmit = () => {
        props.cbValidate(idstation);
    }

    return (
        <Modal isOpen={props.open} onClose={onClose} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Choose the IDStation for which you want to generate files.</ModalHeader>
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>IDStation</FormLabel>
                        <Input
                            value={idstation} 
                            onChange={(event) => {setIdstation(event.target.value)}}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onSubmit}>Generate</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default IDStationModal;